<template>
  <div>
    <!----------------------------- Page 1 -------------------------------->
    <div v-if="page === 1" class="flex flex-col gap-y-5">
      <div class="flex flex-col gap-y-1">
        <h1 class="font-EffraM text-black text-2xl">Création du compte</h1>
        <span class="font-EffraR text-lg"
          >Rejoignez dok.ma et bénéficiez de nos fonctionnalités
          gratuitement</span
        >
      </div>
      <form v-on:submit.prevent="onCreate" class="flex flex-col gap-y-5">
        <div
          class="
            bg-grayfoot
            shadow-none
            xl:shadow
            rounded-cu
            px-5
            py-3
            w-full
            flex flex-col
            gap-y-5
          "
        >
          <div class="flex justify-between items-center">
            <h3 class="text-xl text-black font-EffraM">
              Information Personnelle
            </h3>
          </div>
          <div class="flex gap-x-8">
            <div class="flex items-center">
              <input
                v-model="data.gender"
                type="radio"
                id="homme"
                class="
                  form-radio
                  border-2 border-solid border-gray-400
                  text-xl
                  mr-2
                  focus:outline-none
                  focus:shadow-none
                "
                :class="errors.gender ? 'border-red-500' : ''"
                value="MALE"
              />
              <label for="homme" class="font-EffraM text-xl">Homme</label>
            </div>
            <div class="flex items-center">
              <input
                v-model="data.gender"
                type="radio"
                id="femme"
                class="
                  form-radio
                  border-2 border-solid border-gray-400
                  text-xl
                  mr-2
                  focus:outline-none
                  focus:shadow-none
                "
                :class="errors.gender ? 'border-red-500' : ''"
                value="FEMALE"
              />
              <label for="femme" class="font-EffraM text-xl">Femme</label>
            </div>
          </div>
          <div class="flex flex-col md:flex-row gap-x-5">
            <!--- First name ---->
            <dok-input
              size="lg"
              d-placeholder="Votre Nom"
              d-type="text"
              :d-model.sync="data.firstName"
              :custom-class="errors.firstName ? 'border-red-500' : ''"
              label="Nom"
            >
            </dok-input>
            <!--- Last name ---->
            <dok-input
              size="lg"
              d-placeholder="Votre Prénom"
              d-type="text"
              :d-model.sync="data.lastName"
              :custom-class="errors.lastName ? 'border-red-500' : ''"
              label="Prénom"
            >
            </dok-input>
          </div>
        </div>

        <div
          class="
            bg-grayfoot
            shadow-none
            xl:shadow
            rounded-cu
            py-8
            px-5
            w-full
            flex flex-col
            gap-y-5
          "
        >
          <div class="flex justify-between items-center">
            <h3 class="text-xl text-black font-EffraM">Contact</h3>
          </div>
          <div class="grid gap-x-5 grid-cols-2">
            <dok-input
              size="lg"
              d-placeholder="Téléphone Mobile"
              d-type="text"
              :d-model.sync="data.mobile"
              label="Téléphone Mobile : "
              :custom-class="errors.mobile ? 'border-red-500' : ''"
            >
              <template v-slot:leftIcon>
                <img src="/icons/tablet.png" />
              </template>
            </dok-input>

            <dok-input
              size="lg"
              d-placeholder="Email"
              d-type="email"
              :d-model.sync="data.email"
              label="Email : "
              :custom-class="errors.email ? 'border-red-500' : ''"
            >
              <template v-slot:leftIcon>
                <img src="/icons/mail.png" />
              </template>
            </dok-input>
          </div>
          <div class="grid gap-x-5 grid-cols-2">
            <dok-input
              size="lg"
              d-placeholder="CIN"
              d-type="text"
              :d-model.sync="data.cin"
              label="CIN : "
              :custom-class="errors.cin ? 'border-red-500' : ''"
            >
            </dok-input>
            <div class="flex flex-col gap-y-1">
              <label class="text-gray-700 font-EffraR">Date de naissance</label>
              <birth-day
                :onSetDate="onBirthday"
                customClass="border-2 border-solid border-gray-300 h-4848 px-3 rounded-cu font-EffraR text-lg w-full bg-white appearance-none"
              />
            </div>
          </div>
          <div class="grid gap-x-5 grid-cols-2">
            <div>
              <dok-input
                size="lg"
                d-placeholder="Mot de passe"
                :d-type="!data.showPassword ? 'password' : 'text'"
                :d-model.sync="data.password"
                label="Mot de passe : "
                :custom-class="errors.password ? 'border-red-500' : ''"
              >
                <template v-slot:rightIcon>
                  <img
                    src="/icons/eye.png"
                    class="cursor-pointer"
                    @click="data.showPassword = !data.showPassword"
                  />
                </template>
              </dok-input>
              <div
                v-if="data.password.length"
                class="h-1 rounded-full my-2"
                :style="
                  `width: ${percentPassword()}%; background: ${percentBackground(
                    percentPassword()
                  )}`
                "
              ></div>
              <div class="flex flex-col" v-if="data.password.length">
                <span
                  class="flex flex-row gap-x-1 items-center font-EffraR"
                  :class="
                    validation.has_length ? 'text-green-500' : 'text-red-500'
                  "
                >
                  <i
                    class="far fa-check-circle"
                    v-if="validation.has_length"
                  ></i
                  ><i
                    class="far fa-times-circle"
                    v-if="!validation.has_length"
                  ></i
                  >Veuillez saisir au moins 8 caractères.
                </span>
                <span
                  class="flex flex-row gap-x-1 items-center font-EffraR"
                  :class="
                    validation.has_number ? 'text-green-500' : 'text-red-500'
                  "
                >
                  <i
                    class="far fa-check-circle"
                    v-if="validation.has_number"
                  ></i
                  ><i
                    class="far fa-times-circle"
                    v-if="!validation.has_number"
                  ></i
                  >Veuillez saisir quelques chiffres
                </span>
                <span
                  class="flex flex-row gap-x-1 items-center font-EffraR"
                  :class="
                    validation.has_lowercase ? 'text-green-500' : 'text-red-500'
                  "
                >
                  <i
                    class="far fa-check-circle"
                    v-if="validation.has_lowercase"
                  ></i
                  ><i
                    class="far fa-times-circle"
                    v-if="!validation.has_lowercase"
                  ></i
                  >Veuillez saisir des lettres minuscules
                </span>
                <span
                  class="flex flex-row gap-x-1 items-center font-EffraR"
                  :class="
                    validation.has_uppercase ? 'text-green-500' : 'text-red-500'
                  "
                >
                  <i
                    class="far fa-check-circle"
                    v-if="validation.has_uppercase"
                  ></i
                  ><i
                    class="far fa-times-circle"
                    v-if="!validation.has_uppercase"
                  ></i
                  >Veuillez saisir des lettres majuscules
                </span>
                <span
                  class="flex flex-row gap-x-1 items-center font-EffraR"
                  :class="
                    validation.has_special ? 'text-green-500' : 'text-red-500'
                  "
                >
                  <i
                    class="far fa-check-circle"
                    v-if="validation.has_special"
                  ></i
                  ><i
                    class="far fa-times-circle"
                    v-if="!validation.has_special"
                  ></i
                  >Veuillez saisir quelques caractères spéciaux
                </span>
              </div>
            </div>
            <dok-input
              size="lg"
              d-placeholder="Mot de passe"
              :d-type="!data.showPassword ? 'password' : 'text'"
              :d-model.sync="data.verfiepassword"
              label="Mot de passe : "
              :custom-class="errors.password ? 'border-red-500' : ''"
            >
              <template v-slot:rightIcon>
                <img
                  src="/icons/eye.png"
                  class="cursor-pointer"
                  @click="data.showPassword = !data.showPassword"
                />
              </template>
            </dok-input>
          </div>
        </div>

        <div class="flex flex-col gap-y-2">
          <div class="flex flex-row items-start">
            <input
              type="checkbox"
              class="
                form-checkbox
                border-2 border-solid border-gray-400
                rounded-none
                text-2xl
                mr-2
                focus:outline-none
                focus:shadow-none
              "
              id="terms"
              v-model="data.isAccepte"
            />
            <span
              for="terms"
              class="text-lg font-EffraM xl:font-EffraR text-black"
              >J'accepte les conditions d'
              <span
                class="text-dokBlue-ultra cursor-pointer"
                @click="onModal('CGU')"
                >utilisation</span
              >
              et de
              <span
                class="text-dokBlue-ultra cursor-pointer"
                @click="onModal('CGV')"
                >vente</span
              >, la politique de confidentialité, et les informations relatives
              aux cookies.</span
            >
          </div>
          <div class="flex flex-row items-start">
            <input
              type="checkbox"
              class="
                form-checkbox
                border-2 border-solid border-gray-400
                rounded-none
                text-2xl
                mr-2
                focus:outline-none
                focus:shadow-none
              "
              id="certification"
              v-model="data.certification"
            />
            <span
              for="certification"
              class="text-lg font-EffraM xl:font-EffraR text-black"
            >
              Je certifie sur l'honneur l'exactitude des renseignements fournis.
            </span>
          </div>
        </div>

        <button
          @click="onCreate()"
          :class="
            !data.password || !data.email || !data.verfiepassword
              ? 'cursor-not-allowed opacity-50'
              : 'cursor-pointer'
          "
          class="
            bg-rosDok-ultra
            h-12
            text-white
            font-EffraR
            text-lg
            uppercase
            border-0
            rounded
          "
        >
          Créer Votre Compte
        </button>
      </form>
      <div class="flex justify-center items-center gap-x-2">
        <span class="text-black font-EffraR">Déja inscrit?</span>
        <a
          href="#signin"
          class="font-EffraM text-rosDok-ultra"
          @click="goSignin"
          >Se connecter</a
        >
      </div>
    </div>
    <!----------------------------- Page 2 -------------------------------->
    <div v-if="page === 2" class="flex flex-col gap-y-3">
      <div class="flex flex-col gap-y-0">
        <div class="flex flex-row items-center">
          <div
            class="flex flex-row items-center gap-x-1 font-EffraR"
            style="color: #6d6d6d"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 16 16"
            >
              <path
                stroke="#767676"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M14 7.667c.002.88-.203 1.748-.6 2.533-.47.941-1.194 1.733-2.088 2.286-.895.554-1.926.847-2.979.847-.88.003-1.748-.203-2.533-.6L2 14l1.267-3.8c-.397-.785-.603-1.653-.6-2.533 0-1.052.293-2.084.847-2.979C4.067 3.794 4.859 3.07 5.8 2.6c.785-.397 1.653-.602 2.533-.6h.334c1.39.077 2.702.663 3.686 1.647S13.923 5.944 14 7.333v.334z"
              />
            </svg>
            Code de vérification
          </div>
        </div>
        <span class="font-EffraR text-black"
          >Un code de vérification vous a été envoyé par SMS</span
        >
      </div>
      <div class="flex flex-row items-center gap-x-3">
        <multi-input
          inputClasses="input-mask"
          :numInputs="6"
          separator=""
          :shouldAutoFocus="true"
          @on-complete="handleOnComplete"
          @on-change="handleOnChange"
          input-type="number"
        />
        <span
          v-if="typeWatch === 'checking'"
          class="flex flex-row items-center font-EffraR text-black gap-x-1"
        >
          <svg
            class="animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="#0A0A0A"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 2v4M12 18v4M4.93 4.93l2.83 2.83M16.24 16.24l2.83 2.83M2 12h4M18 12h4M4.93 19.07l2.83-2.83M16.24 7.76l2.83-2.83"
            />
          </svg>
          Checking ...
        </span>
        <span
          v-if="typeWatch === 'uncurrect'"
          class="flex flex-row items-center font-EffraR text-red-600 gap-x-1"
        >
          <svg
            class="animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="#0A0A0A"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 2v4M12 18v4M4.93 4.93l2.83 2.83M16.24 16.24l2.83 2.83M2 12h4M18 12h4M4.93 19.07l2.83-2.83M16.24 7.76l2.83-2.83"
            />
          </svg>
          Code not currect
        </span>
        <span
          v-if="typeWatch === 'verified'"
          class="flex flex-row items-center font-EffraM gap-x-1"
          style="color: #30971f"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="#30971F"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M22 11.08V12c-.001 2.156-.7 4.255-1.99 5.982-1.292 1.727-3.107 2.99-5.175 3.602-2.068.611-4.278.538-6.3-.21-2.023-.747-3.75-2.128-4.924-3.937-1.174-1.809-1.731-3.949-1.59-6.1.143-2.152.976-4.2 2.377-5.84C5.8 3.857 7.693 2.715 9.796 2.24c2.104-.475 4.304-.258 6.274.62"
            />
            <path
              stroke="#30971F"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M22 4L12 14.01l-3-3"
            />
          </svg>
          Verified
        </span>
      </div>
      <div class="flex gap-x-1">
        <span class="text-black font-EffraR"
          >Vous n'avez pas reçu de code?</span
        >
        <a
          href="#reSendCode"
          class="text-rosDok-ultra font-EffraM"
          @click="reSendVerificationCode"
          >Re-envoyer</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
import { mapActions } from "vuex";
import birthDay from "@/views/global-components/birthday";
import multiInput from "@/views/global-components/multiInput";
import modal from "@/views/global-components/modal";

//Conditions
import cgu from "@/views/statics/cgu";
import cgv from "@/views/statics/cgv";

export default {
  components: { modal, cgu, cgv, birthDay, multiInput }, // Components
  data() {
    return {
      errors: {},
      modal: {
        is: false,
        type: null
      },
      data: {
        password: "",
        isAccepte: false,
        certification: false,
        showPassword: false
      },
      validation: {
        has_number: false,
        has_lowercase: false,
        has_uppercase: false,
        has_special: false,
        has_length: false
      },
      acceptConditions: false,
      checking: false,
      verified: false,
      verificationCode: null,
      page: 1,
      token: null,
      typeWatch: ""
    };
  },
  watch: {
    verificationCode(newVal, oldVal) {
      if (!newVal.length) {
        this.typeWatch = "";
        return;
      }

      if (newVal.length) {
        if (newVal.length > 5) {
          this.typeWatch = "checking";
          this.verifiedMobile();
        }
      }
    },
    validation: {
      handler(newValue, oldValue) {
        this.percentPassword();
      },
      deep: true
    },
    "data.password"(newVal, oldVal) {
      this.validation.has_number = /\d/.test(this.data.password);
      this.validation.has_lowercase = /[a-z]/.test(this.data.password);
      this.validation.has_uppercase = /[A-Z]/.test(this.data.password);
      this.validation.has_length = this.data.password.length > 7;
      this.validation.has_special = /[!@#\$%\^\&*\)\(+=._-]/.test(
        this.data.password
      );
    }
  },
  mounted() {
    EventBus.$on("SingUp_To_Activation_Mobile", token => {
      if (!token) {
        console.log("Token Not Currect");
        return;
      }
      this.token = token;
      this.page = 2;
      this.$forceUpdate();
    });
  },
  methods: {
    ...mapActions("user", [
      "PATIENT_SENT_MOBILE_ACTIVATION",
      "PATIENT_VERIFIED_MOBILE_ACTIVATION"
    ]),
    async verifiedMobile() {
      this.$vs.loading();

      const {
        ok,
        data,
        accessToken,
        token,
        errCode
      } = await this.PATIENT_VERIFIED_MOBILE_ACTIVATION({
        token: this.token,
        mobileToken: this.verificationCode
      });

      if (!ok) {
        if (errCode === "UNVERIFIED_IDENTITY") {
          EventBus.$emit("BOOKING-ACCOUNT-NOT-VERIFY", token);
          return this.$vs.loading.close();
        }

        this.typeWatch = "uncurrect";
        return this.$vs.loading.close();
      }

      if (ok) {
        this.typeWatch = "";

        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("userInfo", JSON.stringify(data));

        const dataAppointment = await this.$TAKE_APPOINTMENT();
        if (dataAppointment.ok) {
          EventBus.$emit(
            "IS_DONE_BOOKING",
            dataAppointment.data.category.title
          );
        }
      }

      return this.$vs.loading.close();
    },
    async reSendVerificationCode() {
      if (!this.token) {
        return;
      }

      this.$vs.loading();
      const { ok, message } = await this.PATIENT_SENT_MOBILE_ACTIVATION({
        token: this.token
      });

      EventBus.$emit("flashDok", {
        type: ok ? "success" : "errorr",
        message: message
      });

      return this.$vs.loading.close();
    },
    async onCreate() {
      event.preventDefault();

      if (this.percentPassword() !== 100) {
        // Validation Strong Password
        return;
      }

      if (!this.data.isAccepte || !this.data.certification) {
        return this.$vs.notify({
          time: 4000,
          text:
            "Merci de cocher les cases pour finaliser la création de votre compte",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-triangle"
        });
      }

      this.$vs.loading();
      this.errors = [];
      this.data.role = "PATIENT";
      const { data } = await this.$store.dispatch("user/REGISTER", this.data);

      if (!data.ok) {
        EventBus.$emit("flashDok", { type: "errorr", message: data.message });
      }

      if (data.ok) {
        if (!data.data.isMobileVerified) {
          this.token = data.token;
          this.$nextTick(() => {
            this.page = 2;
            this.errors = [];
          });
          return this.$vs.loading.close();
        }

        EventBus.$emit("flashDok", { type: "success", message: data.message });
        EventBus.$emit("TO-SIGNIN-PAGE");
      }

      this.errors = [];
      this.$vs.loading.close();
    },
    percentPassword() {
      let precent = 0;
      if (this.validation.has_number) precent = precent + 20;
      if (this.validation.has_lowercase) precent = precent + 20;
      if (this.validation.has_uppercase) precent = precent + 20;
      if (this.validation.has_special) precent = precent + 20;
      if (this.validation.has_length) precent = precent + 20;

      return precent;
    },
    percentBackground(val) {
      if (val === 0) return "#DDD";
      if (val === 20) return "#EF4444";
      if (val === 40) return "#DB2777";
      if (val === 60) return "#D97706";
      if (val === 80) return "#fbbf24";
      if (val === 100) return "#10B981";
    },
    goSignin() {
      EventBus.$emit("TO-SIGNIN-PAGE");
    },
    onBirthday(value) {
      this.data.birthday = value;
    },
    onModal(type) {
      this.modal = { type, is: true };
    },
    handleOnComplete(value) {
      this.verificationCode = value;
    },
    handleOnChange(value) {
      this.verificationCode = value;
    }
  }
};
</script>

<style lang="scss">
.input-mask {
  @apply h-16 w-16 border-2 border-solid border-gray-500 rounded-cu px-1 text-4xl font-EffraM text-center;
  &:focus {
    @apply border-dokBlue-ultra;
  }
}

.input-mask::-webkit-inner-spin-button,
.input-mask::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
